import ApiService from "./api.service";

const CirurgiaService = {
  async getCirurgias() {
    return await ApiService.get("/cirurgias");
  },

  async getCirurgia(id) {
    return await ApiService.get(`/cirurgias/${id}`);
  },

  async imprimirCirurgia(id) {
    return await ApiService.getBlob(`/cirurgias/imprimir/${id}`);
  },
};

export default CirurgiaService;
